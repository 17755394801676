.layout-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: url("../../static/img/home-bg.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.layout-header {
    background-color: transparent;
    color: white;
    min-height: 80px;
}

.layout-main {
    color: white;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}
