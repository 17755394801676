@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.full-page-menu-links-container a {
    color: #ffffff !important;
    font-size: 1.25rem;
    margin: 0.5rem;
    padding: 0.25rem;
}

.full-page-menu-links-container a:hover {
    border-bottom: 1px solid #4bffa5 !important;
    color: #4bffa5 !important;
    font-weight: 400;
    text-decoration: none;
}

.full-page-menu-links-container .active {
    border-bottom: 1px solid #4bffa5 !important;
    color: #4bffa5 !important;
    font-weight: 400 !important;
}

.close-icon {
    top: 20px;
    right: 20px;
    fill: #ffffff;
}

.full-page-menu {
    background-color: #1b1b1e;
    position: absolute;
    z-index: 10;
}

.full-page-menu-links-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 100vw;
}

.header-link:hover {
    border-bottom: 1px solid #4bffa5 !important;
    text-decoration: none;
    font-weight: 400;
    color: #4bffa5;
}

.header-link.active {
    font-weight: 400 !important;
    border-bottom: 1px solid #4bffa5 !important;
    color: #4bffa5;
}

.header-link {
    color: #ffffff;
}

.header-container {
    min-height: 85px;
    position: fixed;
    width: 100%;
    z-index: 10;
}

.header-container.active {
    background-color: #1b1b1e;
}

.menu-icon {
    fill: #ffffff;
    position: absolute;
    top: 20px;
    right: 20px;
}

.vinson-logo {
    position: absolute;
    top: 10px;
    left: 10px;
}

.layout-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: url(/static/media/home-bg.d36a5a6d.jpg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.layout-header {
    background-color: transparent;
    color: white;
    min-height: 80px;
}

.layout-main {
    color: white;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.typewriter-line {
    height: 30px;
    font-family: "Courier Prime", monospace;
}

.hide {
    opacity: 0;
}

.pri-font {
    font-family: "Poppins", sans-serif !important;
    color: #ffffff !important;
    line-height: 1.4 !important;
    letter-spacing: 2px !important;
}

.pri-font-light {
    color: #ffffff !important;
    font-family: "Poppins", sans-serif !important;
    line-height: 1.4 !important;
    letter-spacing: 2px !important;
    font-weight: 200 !important;
}

.pri-font-bold {
    color: #ffffff !important;
    font-family: "Poppins", sans-serif !important;
    line-height: 1.4 !important;
    letter-spacing: 2px !important;
    font-weight: 700 !important;
}

.green-font {
    color: #4bffa5 !important;
}

.gold-font {
    color: #e0a458 !important;
}

.translucent-bg {
    background-color: rgba(50, 50, 50, 0.7) !important;
}

.skill-bar-container {
    height: 10px;
    background-color: #ffffff;
}

.skill-bar {
    height: 10px;
    background-color: #4bffa5;
}

.avatar-img {
    border-radius: 50%;
    background-color: #6d6d6d;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.qualification {
    border: 1px solid #ffffff;
}

.flipcard-container {
    height: 400px;
}

.flipcard-inner-container {
    position: relative;
    height: 100%;
    width: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

.flipcard-inner-container.flipped {
    transform: rotateY(180deg);
}

.flipcard-front,
.flipcard-back {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, 0.6);
}

.flipcard-back {
    transform: rotateY(180deg);
}

.btn:hover {
    background-color: white !important;
    color: #1b1b1b !important;
}

