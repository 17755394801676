.header-link:hover {
    border-bottom: 1px solid #4bffa5 !important;
    text-decoration: none;
    font-weight: 400;
    color: #4bffa5;
}

.header-link.active {
    font-weight: 400 !important;
    border-bottom: 1px solid #4bffa5 !important;
    color: #4bffa5;
}

.header-link {
    color: #ffffff;
}

.header-container {
    min-height: 85px;
    position: fixed;
    width: 100%;
    z-index: 10;
}

.header-container.active {
    background-color: #1b1b1e;
}

.menu-icon {
    fill: #ffffff;
    position: absolute;
    top: 20px;
    right: 20px;
}

.vinson-logo {
    position: absolute;
    top: 10px;
    left: 10px;
}
